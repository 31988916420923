import clsx from 'clsx';
import React from 'react';

interface Props {
  icon: IconName;
  className: string;
  theme?: 'dark' | 'light';
}

export function Icon({ icon, className, theme = 'light', ...rest }: Props) {
  return (
    <svg
      theme={theme}
      {...rest}
      viewBox="0 0 32 32"
      className={clsx('inline-block align-middle', className)}
    >
      <use href={`#icon-${icon}`} />
    </svg>
  );
}

export const icons = [
  'action-add',
  'action-subtract',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'artist-single',
  'audio-sample-rate',
  'audio-track-group',
  'audio-wave',
  'buffer',
  'calendar-song',
  'calendar',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'chart-bars',
  'chart-line',
  'chart-scatter',
  'chart-up',
  'check-thick',
  'check',
  'check-thick',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clap',
  'clear',
  'communities',
  'composition',
  'contact',
  'contract-single',
  'contract-group',
  'control-end',
  'control-forward',
  'control-pause',
  'control-play',
  'control-resume',
  'control-rewind',
  'control-skip-back',
  'controls',
  'copy',
  'country',
  'cross-thick',
  'cross',
  'delete',
  'discs',
  'discuss',
  'download',
  'edit',
  'exit',
  'features-list',
  'file-audio',
  'file-document',
  'folder',
  'high-priority-thick',
  'high-priority',
  'info',
  'input',
  'journey',
  'location',
  'locked',
  'logo',
  'lullaby',
  'mail-group',
  'mail-single',
  'mail-single-open',
  'maintenance',
  'menu-dashboard',
  'menu-list',
  'minus',
  'money',
  'more',
  'music-dance',
  'music-dj',
  'music-drum-kit',
  'music-drum-snare',
  'music-guitar',
  'music-heart',
  'music-microphone',
  'music-note-beamed',
  'music-note',
  'music-sax',
  'music-sheet',
  'music-trumpet',
  'next-level',
  'news',
  'notification',
  'open-in-browser',
  'open-pane',
  'pane-close',
  'paragraph',
  'pause',
  'performance',
  'phone',
  'piano',
  'picture',
  'playlist',
  'plus',
  'product-cd',
  'product-digital',
  'product-group',
  'product-single',
  'radio-wave',
  'radio',
  'receipt',
  'record',
  'reorder',
  'replace',
  'report',
  'search-list',
  'search',
  'share',
  'social-amazon-music',
  'social-apple-music',
  'social-audiomack',
  'social-bandcamp',
  'social-bandsintown',
  'social-deezer',
  'social-discord',
  'social-facebook',
  'social-feature-fm',
  'social-instagram',
  'social-isni',
  'social-mail',
  'social-musicbed',
  'social-pandora',
  'social-shazam',
  'social-slack',
  'social-snapchat',
  'social-songkick',
  'social-soundcloud',
  'social-spotify',
  'social-tidal',
  'social-tiktok',
  'social-triller',
  'social-twitch',
  'social-x',
  'social-youtube',
  'songwriting',
  'sort',
  'sound-recording-single',
  'spiral',
  'star-empty',
  'state-backward',
  'state-forward',
  'streaming-audio',
  'success',
  'support',
  'table',
  'tag-group',
  'text-align-centre',
  'text-align-left',
  'text-align-right',
  'text-bold',
  'text-clear',
  'text-code',
  'text-heading-1',
  'text-heading-2',
  'text-heading-3',
  'text-heading-4',
  'text-indent',
  'text-italic',
  'text-link',
  'text-mention',
  'text-ordered-list',
  'text-outdent',
  'text-paragraph',
  'text-quote',
  'text-strike',
  'text-unordered-list',
  'travel',
  'tree-structure',
  'upload',
  'user-group',
  'user-none',
  'user-single',
  'vaporwave',
  'video',
  'view-external',
  'view-new',
  'visibility-off',
  'visibility-on',
  'volume-mute',
  'volume',
  'emoji'
];
export type IconName = (typeof icons)[number];
