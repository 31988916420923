import { InertiaLinkProps, Link } from '@inertiajs/react';
import clsx from 'clsx';
import React from 'react';

import { Icon, IconName } from '@/components/icon/Icon';
import { withTheme } from '@/components/theme/withTheme';
import {
  ActionTextBase,
  ActionTextBaseEmphasis,
  ActionTextSm
} from '@/components/typography/actions/ActionText';

interface ComponentProps {
  ref?: React.Ref<React.ReactNode>;
  className?: string;
  iconClassName?: string;
  as?: React.ElementType;
  align?: string;
  size?: ButtonSize;
  rounding?: ButtonRounding;
  onDark?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  iconOnly?: boolean;
  prefixIcon?: IconName;
  suffixIcon?: IconName;
}

interface ButtonElementProps
  extends ComponentProps,
    Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'as' | 'label'> {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  href?: never;
  external?: never;
  ref?: any;
}

interface LinkElementProps
  extends ComponentProps,
    Omit<React.HTMLProps<HTMLAnchorElement>, 'size' | 'as'> {
  href: string;
  external: true;
  type?: never;
  ref?: any;
}

interface InertiaLinkElementProps extends ComponentProps, Omit<InertiaLinkProps, 'size' | 'as'> {
  href: string;
  external?: false;
  type?: never;
}

export type ButtonProps = ButtonElementProps | InertiaLinkElementProps | LinkElementProps;
export type Props = ButtonProps & {
  variant?: ButtonVariant;
  ref?: HTMLButtonElement;
};
export type ComposedButtonProps = ButtonProps & {
  label?: React.ReactNode;
};

export type ButtonSize = 'xs' | 'sm' | 'lg';
export type ButtonRounding = 'pill' | 'square';

export type ButtonVariant =
  | 'default'
  | 'high-impact'
  | 'subtle'
  | 'low-impact'
  | 'icon-emphasis'
  | 'danger'
  | 'subtle-danger';

/**
 * The button component used by the buttons in the system.
 * This component is not intended to be used directly, but rather
 * composed as part of a composed button component.
 */
const ButtonComponent = (
  {
    children,
    className,
    iconClassName,
    as = 'button',
    align = 'center',
    variant = 'default',
    rounding = 'pill',
    size = 'lg',
    onDark = false,
    external = false,
    loading = false,
    disabled = false,
    iconOnly = false,
    prefixIcon,
    suffixIcon,
    ...props
  }: Props,
  ref: React.Ref<React.ReactNode>
) => {
  const Component: any = getComponent(as, external, props.href);
  const ActionTextComponent = getActionTextType(size);
  const themeVariant = onDark ? 'dark' : 'light';
  const isLarge = size === 'lg';

  prefixIcon = loading ? 'buffer' : prefixIcon;

  const typeProp = as === 'button' ? { type: 'button' } : null;

  return (
    <Component
      ref={ref}
      disabled={disabled}
      theme={themeVariant}
      {...typeProp}
      className={clsx(
        className,
        getButtonAlignment(align),
        getButtonSize(isLarge, variant, iconOnly),
        getButtonRounding(size, variant, rounding),
        'group inline-flex items-center',
        'hover:cursor-pointer',
        'disabled:cursor-not-allowed disabled:bg-opacity-80',
        '[&:not(:active)]:focus:ring-2',
        focus('ring-black ring-offset-white'),
        focus('dk:ring-white dk:ring-offset-black'),
        loading && 'opacity-75 min-w-0'
      )}
      {...props}
    >
      {prefixIcon ? (
        <StyledIcon
          className={iconClassName}
          isLarge={isLarge}
          icon={prefixIcon}
          prefix={true}
          variant={variant}
          size={size}
          loading={loading}
          themeVariant={themeVariant}
        />
      ) : null}

      {children && !iconOnly && (
        <ActionTextComponent
          className={clsx(
            'group-focus:underline group-active:no-underline max-w-full',
            '[text-decoration:inherit] [text-underline-position:from-font]',
            loading && 'truncate overflow-hidden'
          )}
        >
          {children}
        </ActionTextComponent>
      )}

      {iconOnly && children}

      {suffixIcon ? (
        <StyledIcon
          isLarge={isLarge}
          icon={suffixIcon}
          prefix={false}
          variant={variant}
          size={size}
          loading={loading}
          themeVariant={themeVariant}
        />
      ) : null}
    </Component>
  );
};

const StyledIcon = ({
  icon,
  prefix,
  variant,
  loading,
  isLarge,
  themeVariant,
  className
}: {
  icon: IconName;
  prefix: boolean;
  variant: ButtonVariant;
  size: ButtonSize;
  loading: boolean;
  isLarge: boolean;
  themeVariant: 'dark' | 'light';
  className?: string;
}) => {
  const iconMargin = getIconMargin(variant);
  const iconSize = getIconSize(variant, isLarge);

  return (
    <span
      theme={themeVariant}
      className={clsx(
        'inline-flex flex-none items-center justify-center',
        prefix ? `mr-${iconMargin}` : `ml-${iconMargin}`,

        variant === 'icon-emphasis' && [
          isLarge ? 'h-10 w-10' : 'h-6 w-6',
          'rounded-full bg-black dk:bg-white',
          'group-focus:bg-black group-focus:dk:bg-white',
          'group-hover:bg-emerald group-hover:dk:bg-amethyst',
          'group-active:bg-cyan-90 group-active:dk:bg-blue-20',
          'group-disabled:bg-non-interactive-on-light group-disabled:dk:bg-non-interactive-on-dark'
        ]
      )}
    >
      <Icon
        theme={themeVariant}
        icon={icon}
        className={clsx(
          className,
          iconSize,
          prefix && loading && 'animate-spin',
          'group-active:drop-shadow-glitch-small',
          'group-disabled:drop-shadow-none',
          variant === 'default' && [
            'text-emerald dk:text-amethyst',
            'group-hover:text-turquoise-10 group-hover:dk:text-violet-100',
            'group-focus:text-emerald group-focus:dk:text-violet-10',
            'group-active:text-cyan-20 group-active:text-shadow-glitch group-active:dk:text-blue-80',
            'group-disabled:text-gray-50 group-disabled:dk:text-gray-50'
          ],
          variant === 'high-impact' && [
            'text-black dk:text-white',
            'group-active:text-cyan-20 group-active:dk:text-blue-80',
            'group-disabled:text-turquoise-50 group-disabled:dk:text-violet-50'
          ],
          variant === 'subtle' && [
            'text-black dk:text-white',
            'group-hover:text-turquoise-20 group-hover:dk:text-violet-60',
            'group-focus:text-black group-focus:dk:text-white',
            'group-active:text-cyan-40 group-active:text-shadow-glitch group-active:dk:text-blue-60',
            'group-disabled:text-gray-60 group-disabled:dk:text-gray-40'
          ],
          variant === 'low-impact' && [
            'text-black dk:text-white',
            'group-hover:text-turquoise-10 group-hover:dk:text-violet-80',
            'group-focus:text-black group-focus:dk:text-white',
            'group-active:text-turquoise-30 group-active:text-shadow-glitch group-active:dk:text-blue-70',
            'group-disabled:text-gray-50 group-disabled:dk:text-gray-50'
          ],
          variant === 'icon-emphasis' && [
            'text-emerald dk:text-amethyst',
            'group-hover:text-turquoise-30 group-hover:dk:text-violet-80',
            'group-focus:text-emerald group-focus:dk:text-amethyst ',
            'group-active:text-cyan-50 group-active:dk:text-blue-50',
            'group-disabled:text-gray-60 group-disabled:dk:text-gray-40'
          ],
          variant === 'danger' && [
            'text-white',
            'group-active:text-white group-active:drop-shadow-none group-active:dk:drop-shadow-none',
            'group-disabled:text-red-60 group-disabled:dk:text-red-40'
          ],
          variant === 'subtle-danger' && [
            'text-functional-on-light-error dk:text-functional-on-dark-error',
            'group-active:text-red-20 group-active:drop-shadow-none group-active:dk:text-red-60 group-active:dk:drop-shadow-none',
            'group-hover:text-red-40 group-hover:dk:text-functional-on-dark-error',
            'group-focus:text-functional-on-light-error group-focus:dk:text-functional-on-dark-error',
            'group-disabled:text-red-70 group-disabled:dk:text-red-20'
          ]
        )}
      />
    </span>
  );
};

function getIconSize(variant: ButtonVariant, isLarge: boolean) {
  switch (variant) {
    default:
      return isLarge ? 'h-6 w-6' : 'h-4 w-4';
  }
}

function getIconMargin(variant: ButtonVariant) {
  switch (variant) {
    case 'icon-emphasis':
      return '4';
    default:
      return '2';
  }
}

function getButtonAlignment(align: string) {
  switch (align) {
    case 'left':
      return 'justify-start';
    case 'right':
      return 'justify-end';
    default:
      return 'justify-center';
  }
}

function getButtonRounding(size: ButtonSize, variant: ButtonVariant, rounding: ButtonRounding) {
  switch (rounding) {
    case 'pill':
      return ['rounded-full active:rounded-full focus:rounded-full'];
    default:
      return [
        size === 'lg' && 'rounded-lg active:rounded-lg focus:rounded-lg',
        size === 'sm' && 'rounded active:rounded focus:rounded',
        size === 'xs' && 'rounded-sm active:rounded-sm focus:rounded-sm'
      ];
  }
}

function getButtonSize(isLarge: boolean, variant: ButtonVariant, iconOnly: boolean) {
  if (iconOnly) {
    return isLarge ? 'p-4' : 'p-2';
  }

  switch (variant) {
    case 'icon-emphasis':
      return isLarge ? 'px-2 py-2' : 'p-1';
    default:
      return isLarge ? 'px-6 py-4' : 'p-2';
  }
}

function getComponent(as: React.ElementType, external: boolean, href?: string): any {
  if (href) {
    return external ? 'a' : Link;
  }
  return as;
}

function getActionTextType(size: ButtonSize) {
  switch (size) {
    case 'xs':
      return ActionTextSm;
    case 'sm':
      return ActionTextBase;
    default:
      return ActionTextBaseEmphasis;
  }
}

const ButtonWithRef = React.forwardRef(ButtonComponent);
export const Button = withTheme(ButtonWithRef as any);
